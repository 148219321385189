import React from "react";
import Layout from "components/layout";
import Seo from "components/seo/seo";
import FastDeliveryForm from "components/growth/forms/fast-delivery-form_pt";

import bgCustomers from "images/bg_customers.jpg";

import BimboLogo from "images/growth-brand-logos/bimbo.png";
import CruzVerdeLogo from "images/growth-brand-logos/cruzverde.png";
import FarmatodoLogo from "images/growth-brand-logos/farmatodo.png";
import JumboLogo from "images/growth-brand-logos/jumbo.png";
import EasyLogo from "images/growth-brand-logos/easy.png";
import MercadoLibreLogo from "images/growth-brand-logos/merecadolibre.png";
import NestleLogo from "images/growth-brand-logos/nestle.png";
import OxxoLogo from "images/growth-brand-logos/oxxo.png";
import RappiLogo from "images/growth-brand-logos/rappi.png";
import TugoLogo from "images/growth-brand-logos/tugo.png";

import ListCheckIcon from "images/list-check-icon.svg";

import TechImg from "images/growth/same-day/tech.svg";
import OptImg from "images/growth/same-day/opt.svg";
import ServImg from "images/growth/same-day/serv.svg";

import "pages/growth/fast-delivery.css";

const logos = [
  TugoLogo,
  JumboLogo,
  RappiLogo,
  EasyLogo,
  NestleLogo,
  MercadoLibreLogo,
  BimboLogo,
  CruzVerdeLogo,
  FarmatodoLogo,
  OxxoLogo,
];

const SameDayPT = () => (
  <Layout className="fast-delivery">
    <Seo 
      title='liftit' 
      description='make same day deliveries'
    />
    <div className="global-wrapper">
      <head>
        <h4>e-commerce</h4>
        <h1>Entregas rápidas e inteligentes</h1>

        <p>
          <b>Melhore e facilite suas entregas com tecnologia.</b>Seu eCommerce com
          uma distribuição eficiente para clientes que desejam receber 
          no mesmo
          dia
        </p>

        <ul style={{ listStyleImage: `url(${ListCheckIcon})` }}>
          <li>Localize e verifique o status de seus pedidos em tempo real</li>
          <li>Controle suas entregas com métricas de satisfação</li>
          <li>99% de eficácia nos prazos de entrega</li>
        </ul>
      </head>

      <div className="form">
        <FastDeliveryForm />
      </div>

      <div
        className="customers"
        style={{ backgroundImage: `url(${bgCustomers})` }}
      >
        <span>Eles confiam em nós</span>
        <div className="brands">
          {logos.map((logo) => (
            <img src={logo} alt={logo} />
          ))}
        </div>
      </div>

      <div className="features">
        <h4>Leve suas entregas para o próximo nível</h4>

        <div className="feature">
          <img src={TechImg} alt="Tecnologia" />
          <h5 className="feature-title">Tecnologia</h5>
          <p>
          Planeje seus pedidos com tecnologia e obtenha rotas de entrega em
            segundos; opera con una distribución eficiente en términos de
            tiempos y costos.
          </p>
        </div>
        <div className="feature">
          <img src={OptImg} alt="Operatividad" />
          <h5 className="feature-title">operabilidade</h5>
          <p>
          Torne suas entregas mais flexíveis por meio de nossa grande frota de
            veículos e proporcione aos seus clientes uma excelente experiência.
            
          </p>
        </div>
        <div className="feature">
          <img src={ServImg} alt="Servicio" />
          <h5 className="feature-title">Serviço</h5>
          <p>
            Notifique seus clientes sobre o status da entrega, Por meio de notificações multicanais (SMS, WhatsApp, e-mail), 
            permitindo que eles localizem
            facilmente seus produtos.{" "}
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default SameDayPT;