import React from "react";
import Person from "images/avatar.png";
import { useForm } from "react-hook-form";

const requiredMessage = "Este campo é obrigatório";
const emailMessage = "Este não é um e-mail válido";
const phoneMessage =
  "Insira um número de telefone com no mínimo 7 dígitos.";

const portalId = "4838246";
const formGuid = "261651b5-f512-4625-969c-9836f583a5de";

const FastDeliveryForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [leadCreated, setLeadCreated] = React.useState(false);
  const [response, setResponse] = React.useState("");

  const onSubmit = (form) => {
    const withDefault = {
      ...form,
      numero_de_empleados_en_tu_empresa: form.deliveries,
      segmento_e_commerce: form.maturity,
      servicio_de_tu_interes_: "Transporte y tecnología",
    };
    delete withDefault.deliveries;
    delete withDefault.maturity;
    const parsed = Object.keys(withDefault).reduce(
      (acc, i) => [...acc, { name: i, value: withDefault[i] }],
      []
    );

    const data = {
      fields: parsed,
    };

    const fetchData = async () => {
      const result = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (result.ok) {
        setLeadCreated(true);
        const json = await result.json();
        setResponse(json.inlineMessage);
      }
    };

    fetchData();
  };

  return leadCreated ? (
    <div
      className="response-text"
      dangerouslySetInnerHTML={{ __html: response }}
    ></div>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="human-talk">
        <div className="avatar">
          <img src={Person} alt="person" />
        </div>
        <div className="information">
          <p>Estamos aquí para você</p>
          <h6>Contata a un experto.</h6>
        </div>
      </div>

      <div className="input_middle">
        <div className="input-container">
          <input
            type="text"
            placeholder="Nome"
            className={errors.firstname && "required"}
            {...register("firstname", { required: true })}
          />
          {errors.firstname && <small>{requiredMessage}</small>}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Sobrenome"
            className={errors.lastname && "required"}
            {...register("lastname", { required: true })}
          />
          {errors.lastname && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input-container">
        <input
          type="text"
          placeholder="Correo empresarial"
          className={errors.email && "required"}
          {...register("email", {
            required: true,
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
          })}
        />
        {errors.email &&
          (errors.email.type === "required" ? (
            <small>{requiredMessage}</small>
          ) : (
            <small>{emailMessage}</small>
          ))}
      </div>

      <div className="input_middle">
        <div className="input-container">
          <select
            className={errors.country && "required"}
            {...register("country", { required: true })}
          >
            <option hidden disabled selected value="">
              País
            </option>
            <option value="Colombia">Colombia</option>
            <option value="Mexico">México</option>
            <option value="Chile">Chile</option>
            <option selected value="Brasil">Brasil</option>
            <option value="Ecuador">Ecuador</option>
          </select>
          {errors.country && <small>{requiredMessage}</small>}
        </div>
        <div className="input-container">
          <input
            type="phone"
            min="1"
            placeholder="# do telefone"
            className={errors.phone && "required"}
            {...register("phone", { required: true, minLength: 7 })}
          />
          {errors.phone &&
            (errors.phone.type === "required" ? (
              <small>{requiredMessage}</small>
            ) : (
              <small>{phoneMessage}</small>
            ))}
        </div>
      </div>

      <div className="input">
        <div className="input-container">
          <input
            type="text"
            placeholder="Nome da empresa"
            className={errors.company && "required"}
            {...register("company", { required: true })}
          />
          {errors.company && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input">
        <div className="input-container">
          <select
            className={errors.deliveries && "required"}
            {...register("deliveries", { required: true })}
          >
            <option hidden disabled selected value="">
              Entregas por mês
            </option>
            <option label="1 a 50 " value="1 a 50">
              1 a 50
            </option>
            <option label="51 a 500" value="51 a 500">
              51 a 500
            </option>
            <option label="501 a 1000" value="501 a 1000">
              501 a 1000
            </option>
            <option label=" Mais de 1000" value="Más de 1000">
              Mais de 1000
            </option>
          </select>
          {errors.deliveries && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input">
        <div className="input-container">
          <select
            className={errors.maturity && "required"}
            {...register("maturity", { required: true })}
          >
            X
            <option hidden disabled selected value="">
            Nível de maturidade do seu e-commerce
            
            </option>
            <option label="Eu não sou um e-commerce" value="Eu não sou um e-commerce">
            Eu não sou um e-commerce
            
            </option>
            <option label="Novo e-Commerce (começando seu e-Commerce)" value="Entrante">
            Novo e-Commerce (Iniciando seu comércio eletrônico)
            </option>
            <option
              label="Emergentes (seu e-Commerce em crescimento)"
              value="Emergentes (comércio eletrônico em crescimento)"
            >
              Emergentes (seu e-Commerce em crescimento)
            </option>
            <option label="Maduro (e-Commerce posicionado)" value="Maduro">
              Maduro (e-Commerce posicionado)
            </option>
            
          </select>
          {errors.maturity && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input">
        <div className="input-container">
          <select
            className={errors.industry && "required"}
            {...register("industry", { required: true })}
          >
            <option hidden disabled selected value="">
              Industria
            </option>

            <option
              label="comércio eletrônico em massa"
              value="electrodomesticos_tecnologia"
            >
              comércio eletrônico em massa
            </option>
            <option label="eletrodomésticos (geladeira, máquina de lavar, secadora)" value="hogar_aseo">
              Hogar y aseo
            </option>
            <option
              label="benfeitorias residenciais (construção)"
              value="ferreteria_construccion_autopartes"
            >
              benfeitorias residenciais (construção)
            </option>
            <option
              label="consumo eletrônico (pequeno)"
              value="productos_para_animales_mascotas"
            >
              consumo eletrônico (pequeno)
            </option>
            <option
              label="Moda e cuidados pessoais"
              value="moda_cuidado_personal"
            >
              Moda e cuidados pessoais
            </option>
            <option
              label="Consumo (FMCGs)"
              value="Oficina_libros_papelería_jugueteria"
            >
              Consumo (FMCGs)
            </option>
            <option label="Empresass de correio" value="Ecommerce">
              Ecommerce
            </option>
            <option
              label="Artigos esportivos"
              value="Logística y Tecnología"
            >
              Artigos esportivos
            </option>
            <option
              label="Artigos de oficina"
              value="Perfumería, Aromas y Esencias"
            >
              Artigos de oficina{" "}
            </option>
            
            <option label="Farmacêutica e saúde" value="otro">
            Farmacêutica e saúde
            </option>
            <option
              label="Acessórios para casa"
              value="accesorios del hogar"
            >
              Acessórios para casa
            </option>
            <option
              label="Partes automotivas"
              value="Piezas de automóviles"
            >
              Partes automotivas
            </option>
          </select>
          {errors.industry && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input">
        <input type="submit" value="Revolucione suas entregas" id="" />
      </div>
    </form>
  );
};

export default FastDeliveryForm;
